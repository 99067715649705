import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap"
import './SearchTeam.scss';
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { navigate } from "gatsby";
const SearchTeam = (props) => {
    const [value, setValue]=useState("")
    const { isMobile } = useDeviceMedia()
    const handlechange = (event) => {
        setValue(event.target.value)
    }

    const handleSubmit=(event)=>{
        event.preventDefault()
        if(value){
            navigate("/about-us/our-team/",{state: { inputValue: value,replace: true}})
        }
        else{
            navigate("/about-us/our-team/")
        }
    }


    return (
        <div className="search-team">
            <Form.Group>
                <Form.Control
                    className={"team-search-input"}
                    required={true}
                    type={"text"}
                    name={"team-search"}
                    // value={""}
                    placeholder={isMobile ? "Name or department" : "Name or department"}
                    onChange={handlechange}
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group>
                <Button aria-label="search" onClick={handleSubmit} className={"button button-filled-green"} >
                    <i className="icon arrow-search"></i>
                </Button>
            </Form.Group>
        </div>





    )
}

export default SearchTeam
