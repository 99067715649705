import React from "react"
import { Container } from "react-bootstrap"
import "./LandingContent.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const LandingContent = ({ data, isPreviewEnabled,isDeveloperDetails, isAreaGuideDetails }) => {
  return (
    <div className={`landing-text-module ${isDeveloperDetails?"dev-text_mod": 
      isAreaGuideDetails ?"areaguide_text-mod":""
    }`}>
    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="text-section_landing">
          <h2 className="heading">{data?.title}</h2>
          {(data?.description?.data?.description||(isPreviewEnabled&&data?.description))&&
          <p className="description">
            <ContentModule Content={data?.description?.data?.description||data?.description} />
          </p>
          }
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default LandingContent
