import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/contact_form.json"
import SearchTeam from "../SearchTeam/SearchTeam"
import DefaultForm from "../forms/default-form-module"
import "./LandingStaticCard.scss"

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const LandingStaticCard = ({ data, isPreviewEnabled, isDeveloperDetails }) => {
  const [show, setShow] = useState(false)

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className={`landing-static-card ${isDeveloperDetails?"dev_details-card":""}`}>
      {!isDeveloperDetails?
      <>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="landing-static-card-content">
            {data?.cards?.map((card, index) => {
              return (
                <div
                  className={`${
                    card.title.includes("Communi") ||
                    card.title.includes("communi")
                      ? "card_team-list community"
                      : card.title.includes("Consultant") ||
                        card.title.includes("consultant")
                      ? "card_team-list customer"
                      : card.title === "Speak to a customer service specialist"
                      ? "card_team-list contact"
                      : "card_team-list"
                  }`}
                >
                  <div className="content-container">
                    <h3>{card?.title}</h3>
                    {(card?.content?.data?.content || (isPreviewEnabled && card?.content) )&&
                    <p className="description">
                      <ContentModule Content={card?.content?.data?.content || card?.content} />
                    </p>
                    }
                    {card?.cta ? (
                      <>
                        {card.cta?.link_type === "onclick" ? (
                          <a onClick={handleModal} href="javascript:void(0)">
                            <button className="button button-filled-green">
                              {card?.cta?.title}
                            </button>
                          </a>
                        ) : (
                          <PreviewCTALink
                            class="button button-filled-green"
                            link={
                              card?.cta?.link
                                ? card?.cta?.link
                                : { external_link: card?.cta?.custom_link }
                            }
                            title={card?.cta?.title}
                            target_window={card?.cta?.target_window}
                          />
                        )}
                      </>
                    ) : (
                      <SearchTeam />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </ScrollAnimation>

      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form contact-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper contact-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields}
                sourceUrl={pageurl}
                formTitle="General Enquiry"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </>
      :
      <>
      <ScrollAnimation
      animateIn="animate__slideInUp"
      animateOnce
      delay={100}
      offset={100}
    >
      <Container>
        <div className="landing-static-card-content">
          {data?.cards?.map((card, index) => {
            return (
              <div
                className="card_team-list">
                <div className="content-container">
                  <h3>{card?.title}</h3>
                  {(card?.content?.data?.content || (isPreviewEnabled && card?.content) )&&
                  <p className="description">
                    <ContentModule Content={card?.content?.data?.content || card?.content} />
                  </p>
                  }
                  {card?.cta?.title&&
                   <PreviewCTALink
                    class="button button-filled-green"
                    link={
                      card?.cta?.link
                        ? card?.cta?.link
                        : { external_link: card?.cta?.custom_link }
                    }
                    title={card?.cta?.title}
                    target_window={card?.cta?.target_window}
                  />
                  }
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </ScrollAnimation>
    </>
      }
    </div>
  )
}

export default LandingStaticCard
